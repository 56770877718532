export interface Filters {
  indexName?: string
  date?: string
  price?: string
  tab?: string
  form_type?: string
  place_department?: string
  place_region?: string
  place_city?: string
  place_zipcode?: string
  bbox?: string | string[]
  activity_type?: string | string[]
  category_tags?: string | string[]
  ha_tags?: string | string[] // TODO CLEAN TYPE, sometime ha_tags is a string sometime is a array of string
  query?: string
  partners?: string
  start_date?: string | object
  min_price?: number
}

export enum FilterKey {
  date = 'date',
  price = 'price',
  tab = 'tab',
  place_department = 'place_department',
  place_region = 'place_region',
  place_city = 'place_city',
  place_zipcode = 'place_zipcode',
  bbox = 'bbox',
  activity_type = 'activity_type',
  category_tags = 'category_tags',
  ha_tags = 'ha_tags',
  query = 'query',
  partners = 'partners',
}
