
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../../interfaces/content.interface'
import { ACTIVITY_FORM_TYPE } from '~/domains/search/interfaces/filters.interface'

export const solidariteUkraine: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: '<span class="Highlighted Color-Primary">#SolidaritéUkraine</span>'
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: "Les associations mobilisées pour l'Ukraine qui ont besoin de vous",
        description: 'À des milliers de kilomètres du conflit, vous pouvez soutenir la population ukrainienne en faisant un don à une association qui lui vient en aide.'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/evt/solidarite--ukraine',
            name: '{haTag}'
          }
        ],
      }
    }
  },
  // Total collected amount
  {
    component: BLOCK_COMPONENT.AMOUNT_CARD,
    id: 'solidarityAmountForUkraine',
    searchable: {
      indexName: SEARCH_ALL_INDEX.INTERNAL_TAGS,
      params: {
        hitsPerPage: 1,
        filters: {
          tag_label: 'Solidarité Ukraine'
        }
      }
    }
  },
  // Projets de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'projectsForUkraine',
    text: {
      title: 'À quelles associations faire un don ?<br>Parcourez les <span class="Highlighted Color-Primary">campagnes de financement</span> et les <span class="Highlighted Color-Primary">cagnottes</span> en cours',
      subtitle: 'Distribution de nourriture, livraison de matériel médical, accueil de réfugiés… Chacun d’entre nous peut aider le peuple ukrainien. Ces projets ont besoin de votre générosité pour se concrétiser et intervenir auprès de la population locale.',
      showAllQueryParams: '?tab=projets&ha_tags={seoHaTag}',
    },
    searchable: {
      indexName: SEARCH_ALL_INDEX.PROJECTS,
      params: {
        hitsPerPage: 50,
        filters: {
          form_type: ACTIVITY_FORM_TYPE.CROWDFUNDING,
          ha_tags: 'Solidarité Ukraine'
        }
      }
    },
  },
  // Associations de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'organizationsForUkraine',
    text: {
      title: 'Les <span class="Highlighted Color-Primary">associations</span> qui apportent leur aide à la population ukrainienne et qui ont besoin de votre soutien',
      subtitle: 'Petite ou grande, chacune de ces associations mobilise ses bénévoles pour soutenir les populations déplacées ou restées sur place en Ukraine. Vous pouvez les aider depuis la France en faisant un don pour contribuer à leurs actions.',
      showAllQueryParams: '?tab=associations&ha_tags={seoHaTag}',
    },
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 50,
        filters: {
          ha_tags: 'Solidarité Ukraine'
        }
      }
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Vous êtes une association de soutien à <span class="Color-Primary">l\'Ukraine</span> ?',
      subtitle: 'Faites appel aux dons en référençant votre projet sur la plateforme HelloAsso.'
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  }
]
