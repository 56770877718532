
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../../interfaces/content.interface'

export const rugby: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: '<span class="Color-Primary">{seoCategory}</span>',
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: 'Rugby',
        description: 'Description'
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/cat/{seoCategory}',
            name: 'rugby'
          }
        ],
        parentCategory: {
          url: 'https://helloasso.com/e/cat/{seoParentCategory}',
          name: '{toLowerParentCategory}'
        },
      }
    }
  },
  // Les <type d'activité> pour <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    // HitList
    id: 'activitiesForTag',
    text: {
      title: 'Agenda des activités et événements de <span class="Highlighted Color-Primary">rugby</span>',
      subtitle: 'Pour rejoindre un club, participer à un événement, assister à un match et bien plus encore !',
      showAllQueryParams: '?tab=activites&category_tags={seoCategory}',
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ACTIVITIES,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Vous faites partie d\'un <span class="Color-Primary">club de sport</span> ?',
      subtitle: 'Gérez, relayez et financez <span class="Color-Primary">gratuitement</span> vos activités avec HelloAsso.'
    }
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Associations de <tag>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    id: 'organizationsForCategory',
    text: {
      title: 'Annuaire des associations de <span class="Highlighted Color-Primary">rugby</span>',
      subtitle: 'Envie de rejoindre une équipe ? Trouvez les clubs de rugby près de chez vous !',
      showAllQueryParams: '?tab=associations&category_tags={seoCategory}',
    },
    // Carousel
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // Les projets pour <tag>
  {
    component: BLOCK_COMPONENT.CAROUSEL_GROUP,
    // Carousel
    id: 'projectsForTag',
    text: {
      title: 'Collectes de dons et financements participatifs de <span class="Highlighted Color-Primary">rugby</span>',
      subtitle: 'Parcourez les projets solidaires qui soutiennent la pratique du rugby.'
    },
    // Carousel
    carousels: [
      // Les <type d'activité> cette semaine
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: 'Projets solidaires de rugby',
          showAllQueryParams: '?tab=projects&category_tags={seoCategory}',
        },
        id: 'ProjectsForRugby',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.PROJECTS,
          params: {
            hitsPerPage: 50,
            filters: {},
          }
        }
      }
    ]
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  },
  // Sitemap
  {
    component: BLOCK_COMPONENT.SITEMAP
  },
  // HelloAsso, comment ça marche ?
  {
    component: BLOCK_COMPONENT.HOW_IT_WORKS
  }
]
