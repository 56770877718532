import { defineStore } from "pinia"
import type { Hit } from '@algolia/client-search'
import type { Filters } from '~/domains/search/interfaces/filters.interface'
import type { HASearchResponse, HitSeoContent, HitTag } from '~/domains/search/interfaces/search.interface'
import type { ContentPage, Location } from '../interfaces/content.interface'

export interface StateThematic {
  page?: ContentPage,
  seoContent?: Hit<HitSeoContent>,
  category?: string,
  parentCategory?: Hit<HitTag>,
  filters?: Filters,
  location?: Location,
  rawResults?: HASearchResponse[]
}

const useThematicStore = defineStore('thematic', {
  state: (): StateThematic => ({
    page: undefined,
    seoContent: undefined,
    category: undefined,
    parentCategory: undefined,
    filters: undefined,
    location: undefined,
    rawResults: undefined
  }),
  getters: {
    getPage: (state) => state.page,
    getRawResults: (state) => state.rawResults as HASearchResponse[]
  },
  actions: {
    setPage (page: ContentPage) {
      this.page = [...page.map(block => ({ ...block }))]
      // useAsyncData needs a truthy return
      return true
    },
    setSeoContent (seoContent: Hit<HitSeoContent>) {
      this.seoContent = seoContent
    },
    setCategory (category: string) {
      this.category = category
    },
    setParentCategory (parentCategory: Hit<HitTag>) {
      this.parentCategory = parentCategory
    },
    setFilters (filters: Filters) {
      this.filters = filters
    },
    setLocation (location: Location) {
      this.location = location
    },
    setRawResults (results: HASearchResponse[]) {
      this.rawResults = results
    }
  }
})

export default useThematicStore
