
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { FILTER_DATE_OPTIONS_KEY } from '~/domains/search/interfaces/filters.interface'
import { BLOCK_COMPONENT, type ContentPage } from '../../interfaces/content.interface'

export const monsEnBaroeul: ContentPage = [
  // Hero
  {
    component: BLOCK_COMPONENT.HERO,
    defaultText: {
      title: 'Les activités et associations {ofCity}<span class="Highlighted Color-Primary">{city}</span>',
    },
    text: {
      title: "{title}",
      description: "{description}",
      logo: "{logo}",
    },
    seoData: {
      meta: {
        title: 'Associations {ofCity}{city} | Annuaire activités et projets',
        description: 'Trouvez des projets ou activités associatives auxquels participer à {city}, et rejoignez des centaines de clubs, amicales, compagnies référencés sur la plateforme HelloAsso.',
      },
      microdata: {
        content: [
          {
            url: 'https://helloasso.com/e/reg/{seoRegion}',
            name: '{region}'
          },
          {
            url: 'https://helloasso.com/e/reg/{seoRegion}/dep/{seoDepartment}',
            name: '{department}'
          },
          {
            url: 'https://helloasso.com/e/reg/{seoRegion}/dep/{seoDepartment}/ville/{seoCity}',
            name: '{city}'
          }
        ]
      }
    }
  },
  // Que faire à <ville>
  // urls parameters for this page are meant to be:
  // - place_region
  // - place_departmenet
  // - place_city
  {
    component: BLOCK_COMPONENT.CAROUSEL_GROUP,
    // SectionTitle
    id: 'whatToDoInCity',
    text: {
      title: 'Agenda : que faire à <span class="Highlighted Color-Primary">{city}</span> ?',
      subtitle: 'Découvrez toutes les activités et animations qui ont lieu en ce moment'
    },
    slug: 'place_city',
    // Carousel
    carousels: [
      // Exemple: Que faire à... cette semaine 
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        id: 'whatToDoThisWeekInCity',
        text: {
          title: '{city} : que faire cette semaine ?',
          showAllQueryParams: `?tab=activites&place_region={region}&place_department={department}&place_city={city}&date=${FILTER_DATE_OPTIONS_KEY.CURRENT_WEEK}`,
        },
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.CURRENT_WEEK
            },
          }
        }
      },
      // Exemple: Que faire à... la semaine prochaine ?
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: '{city} : que faire la semaine prochaine ?',
          showAllQueryParams: `?tab=activites&place_region={region}&place_department={department}&place_city={city}&date=${FILTER_DATE_OPTIONS_KEY.NEXT_WEEK}`,
        },
        id: 'whatToNextWeekInCity',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.NEXT_WEEK
            },
          }
        }
      },
      // Exemple: Que faire à... le mois prochain ?
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: '{city} : que faire le mois prochain ?',
          showAllQueryParams: `?tab=activites&place_region={region}&place_department={department}&place_city={city}&date=${FILTER_DATE_OPTIONS_KEY.NEXT_MONTH}`,
        },
        id: 'whatToDoNextMonthInCity',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.NEXT_MONTH
            },
          }
        }
      },
      // Exemple: Toutes les activités
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: '{city} : toutes les activités',
          showAllQueryParams: '?tab=activites&place_region={region}&place_department={department}&place_city={city}',
        },
        id: 'allActivitiesInCity',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.ACTIVITIES,
          params: {
            hitsPerPage: 50,
            filters: {
              date: FILTER_DATE_OPTIONS_KEY.CURRENT_WEEK
            },
          }
        }
      }
    ]
  },
  // SEO 1
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_1,
    text: {
      section1: "{section1}"
    }
  },
  // Projets à <ville>
  {
    component: BLOCK_COMPONENT.CAROUSEL_GROUP,
    // SectionTitle
    id: 'projectsInCity',
    slug: 'place_city',
    text: {
      title: 'Les projets de financement participatif à <span class="Highlighted Color-Primary">{city}</span>',
      subtitle: 'Vous souhaitez vous engager pour une cause ? Voici ce qu’il se passe en ce moment.'
    },
    // Carousels
    carousels: [
      {
        component: BLOCK_COMPONENT.CAROUSEL,
        text: {
          title: 'Projets de financement participatif à {city}',
          showAllQueryParams: '?tab=projets&place_region={region}&place_department={department}&place_city={city}',
        },
        id: 'allProjectsInCity',
        useUrlFilters: true,
        searchable: {
          indexName: SEARCH_ALL_INDEX.PROJECTS,
          params: {
            hitsPerPage: 50,
            filters: {},
          }
        }
      }
    ]
  },
  // SEO 2
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_2,
    text: {
      section2: "{section2}"
    }
  },
  // Inscription
  {
    component: BLOCK_COMPONENT.SIGN_UP,
    text: {
      title: 'Vous êtes une association {ofCity}<span class="Color-Primary">{city}</span> ?',
    },
    id: 'signUp',
    slug: 'place_city'
  },
  // Associations à <ville>
  {
    component: BLOCK_COMPONENT.HIT_LIST,
    id: 'organizationsInCity',
    text: {
      title: 'Annuaire : les associations {ofCity}<span class="Highlighted Color-Primary">{city}</span>',
      subtitle: 'Bénévolat, don, engagement rejoignez des centaines de clubs, amicales, compagnies.',
      showAllQueryParams: '?tab=associations&place_region={region}&place_department={department}&place_city={city}',
    },
    useUrlFilters: true,
    searchable: {
      indexName: SEARCH_ALL_INDEX.ORGANIZATIONS,
      params: {
        hitsPerPage: 50,
        filters: {}
      }
    }
  },
  // SEO 3
  {
    component: BLOCK_COMPONENT.SEO_CONTENT_3,
    text: {
      section3: "{section3}"
    }
  },
  {
    component: BLOCK_COMPONENT.FAQ
  },
  // Sitemap
  {
    component: BLOCK_COMPONENT.SITEMAP,
    showCities: true
  },
  // HelloAsso, comment ça marche ?
  {
    component: BLOCK_COMPONENT.HOW_IT_WORKS
  }
]
