import { SEARCH_ALL_INDEX, type HASearchResponse } from '~/domains/search/interfaces/search.interface'
import type { HaMultipleQueriesQueryUI } from '~/domains/search/interfaces/query.interface'
import type { ContentBlock } from '../interfaces/content.interface'
import type { BlockVariablesStatic } from '../interfaces/block.interface'

// prepare query for page content
export const prepareContentPageQueryUI = (filters): HaMultipleQueriesQueryUI[] => {
  const queries = []

  const empty_params_all = [
    'place_city',
    'place_department',
    'place_region',
    'category_tags',
    'activity_type',
    'ha_tags'
  ]

  const empty_params = empty_params_all.filter(
    (key) => !filters[key]
  )

  const queryContent: HaMultipleQueriesQueryUI = {
    indexName: SEARCH_ALL_INDEX.CONTENT,
    params: {
      hitsPerPage: 1,
      filters: {
        ...filters,
        empty_params
      }
    }
  }

  queries.push(queryContent)

  if (filters.category_tags) {
    const queryTags: HaMultipleQueriesQueryUI = {
      indexName: SEARCH_ALL_INDEX.TAGS,
      params: {
        hitsPerPage: 1,
        filters: {
          tag_label: filters.category_tags
        }
      }
    }

    queries.push(queryTags)
  }

  // TODO CO - add query for cities
  if (filters.place_region || filters.place_department || filters.place_city) {
    const empty_params_cities_all = [
      'place_city',
      'place_department',
      'place_region',
    ]

    const empty_params_cities = empty_params_cities_all.filter(
      (key) => !filters[key]
    )

    const queryTags: HaMultipleQueriesQueryUI = {
      indexName: SEARCH_ALL_INDEX.CITIES,
      params: {
        hitsPerPage: 1,
        filters: {
          ...filters,
          empty_params: empty_params_cities
        }
      }
    }

    queries.push(queryTags)
  }
  return queries
}

export const excludeFirstResults = (results: HASearchResponse[], length) => {
  return results.filter((_result, index) => index >= length)
}

export const excludeContentResult = (results: HASearchResponse[]) => {
  return results.filter(result => !result.index.includes(SEARCH_ALL_INDEX.CONTENT) && !result.index.includes(SEARCH_ALL_INDEX.TAGS) || result.index.includes(SEARCH_ALL_INDEX.INTERNAL_TAGS))
}

export const getContentResult = (results: HASearchResponse[]) => {
  return results.find(result => result.index.includes(SEARCH_ALL_INDEX.CONTENT))
}

export const getCitiesResult = (results: HASearchResponse[]) => {
  return results.find(result => result.index.includes(SEARCH_ALL_INDEX.CITIES))
}

export const getSearchableResults = (results: HASearchResponse[]) => {
  return results.filter(
    result =>
      result.index.includes(SEARCH_ALL_INDEX.INTERNAL_TAGS)
      || result.index.includes(SEARCH_ALL_INDEX.ACTIVITIES)
      || result.index.includes(SEARCH_ALL_INDEX.PROJECTS)
      || result.index.includes(SEARCH_ALL_INDEX.ORGANIZATIONS)
  )
}

export const getTagResult = (results: HASearchResponse[]) => {
  return results.find(result => result.index.includes(SEARCH_ALL_INDEX.TAGS))
}

export const aggregateSearches = (
  showCities: boolean,
  showDepartments: boolean,
  category: string,
  parentCategory: string,
  city: string,
  department: string,
  region: string,
): HaMultipleQueriesQueryUI[] => {
  const searchables: HaMultipleQueriesQueryUI[] = []
  // Searchable for categories if hasCategoryInUrl
  if (category) {
    const searchable: HaMultipleQueriesQueryUI = {
      indexName: SEARCH_ALL_INDEX.TAGS,
      params: {
        hitsPerPage: 12,
        query: '',
        filters: {
          tag_label: [{ not: { eq: category } }],
          parent_label: parentCategory || category
        },
        page: 0
      }
    }
    searchables.push(searchable)
  }
  // Searchable if we show department
  if (showDepartments) {
    const searchable: HaMultipleQueriesQueryUI = {
      indexName: SEARCH_ALL_INDEX.CITIES,
      params: {
        hitsPerPage: 13, // Occitanie has the most departments amount: 13
        query: '',
        filters: {
          place_region: region,
          empty_params: [
            'place_city',
            {
              not: { eq: 'place_department' }
            }
          ]
        },
        page: 0
      }
    }
    searchables.push(searchable)
  }
  // Searchable if we show cities
  if (showCities) {
    const filters: HaMultipleQueriesQueryUI['params']['filters'] = {
      empty_params: [{ not: { eq: 'place_city' } }],
      place_department: department,
      place_region: region
    }

    if (city) {
      filters.place_city = [{ not: { eq: city } }]
    }
    const searchable: HaMultipleQueriesQueryUI = {
      indexName: SEARCH_ALL_INDEX.CITIES,
      params: {
        hitsPerPage: 12,
        query: '',
        filters,
        page: 0
      }
    }
    searchables.push(searchable)
  }

  return searchables
}
